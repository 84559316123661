import { jsx as _jsx } from "react/jsx-runtime";
import Table from '@fp-suite/core-ui/src/components/Table';
import React from 'react';
var FrieghtPalletInfo = function (_a) {
    var freightPalletInfo = _a.freightPalletInfo;
    console.log('freightPalletInfo', freightPalletInfo);
    var freightPalletPackageInfoList = [
        {
            title: (_jsx("div", { children: _jsx("h1", { children: "productId" }, void 0) }, void 0)),
            dataIndex: 'productId',
            render: function (productId) {
                return _jsx("h1", { children: productId }, void 0);
            }
        },
        {
            title: (_jsx("div", { children: _jsx("h1", { children: "Pallet Seq Id" }, void 0) }, void 0)),
            dataIndex: 'palletSeqId',
            render: function (palletSeqId) {
                return _jsx("h1", { children: palletSeqId }, void 0);
            }
        },
        {
            title: (_jsx("div", { children: _jsx("h1", { children: "Pallet Dimension" }, void 0) }, void 0)),
            dataIndex: 'palletDimension',
            render: function (palletDimension) {
                return _jsx("h1", { children: palletDimension }, void 0);
            }
        },
        {
            title: (_jsx("div", { children: _jsx("h1", { children: "Pallet Weight" }, void 0) }, void 0)),
            dataIndex: 'palletWeight',
            render: function (palletWeight) {
                return _jsx("h1", { children: palletWeight }, void 0);
            }
        }
    ];
    return (_jsx("div", { children: _jsx(Table, { isPaginated: false, dataSource: freightPalletInfo, columns: freightPalletPackageInfoList }, 'PalletList') }, void 0));
};
export default FrieghtPalletInfo;
